import revive_payload_client_2q83OqER9W from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.0_axios@1.6.8_encodi_2vi2rgxsanybzwpnbir2uncmk4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_BY7dyDEUEY from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.0_axios@1.6.8_encodi_2vi2rgxsanybzwpnbir2uncmk4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_f5Z41H3GNn from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.0_axios@1.6.8_encodi_2vi2rgxsanybzwpnbir2uncmk4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import sentry_6a0ENEp60i from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.658_encoding@0.1.13_ioredis@5.3.2_rollup@4.14.1_typescript@5.4.5_vue@3.4.21_typescript@5.4.5_/node_modules/@edco/apollo/dist/runtime/plugins/sentry.js";
import logger_SGih1q6VIs from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.658_encoding@0.1.13_ioredis@5.3.2_rollup@4.14.1_typescript@5.4.5_vue@3.4.21_typescript@5.4.5_/node_modules/@edco/apollo/dist/runtime/plugins/logger.js";
import payload_client_LS8Qh7kJH4 from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.0_axios@1.6.8_encodi_2vi2rgxsanybzwpnbir2uncmk4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_fN4FW98lXd from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.0_axios@1.6.8_encodi_2vi2rgxsanybzwpnbir2uncmk4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_MSCPopQ6GL from "/opt/node_app/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@4.14.1_typescript@5.4.5_vue@3.4.21_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/node_app/app/.nuxt/components.plugin.mjs";
import prefetch_client_KMYLyoyQBP from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.0_axios@1.6.8_encodi_2vi2rgxsanybzwpnbir2uncmk4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import hydrated_ESeDyKLBMf from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.658_encoding@0.1.13_ioredis@5.3.2_rollup@4.14.1_typescript@5.4.5_vue@3.4.21_typescript@5.4.5_/node_modules/@edco/apollo/dist/runtime/plugins/hydrated.js";
import tooltip_Iij0orJlV0 from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.658_encoding@0.1.13_ioredis@5.3.2_rollup@4.14.1_typescript@5.4.5_vue@3.4.21_typescript@5.4.5_/node_modules/@edco/apollo/dist/runtime/plugins/tooltip.js";
import gtag_0bAPy2C5WT from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.658_encoding@0.1.13_ioredis@5.3.2_rollup@4.14.1_typescript@5.4.5_vue@3.4.21_typescript@5.4.5_/node_modules/@edco/apollo/dist/runtime/plugins/gtag.js";
import theme_rCE95HiHxn from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.658_encoding@0.1.13_ioredis@5.3.2_rollup@4.14.1_typescript@5.4.5_vue@3.4.21_typescript@5.4.5_/node_modules/@edco/apollo/dist/runtime/plugins/theme.js";
import middleware_qgtgR0YfcG from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.658_encoding@0.1.13_ioredis@5.3.2_rollup@4.14.1_typescript@5.4.5_vue@3.4.21_typescript@5.4.5_/node_modules/@edco/apollo/dist/runtime/plugins/middleware.js";
import metrics_vl2jnLjih9 from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.658_encoding@0.1.13_ioredis@5.3.2_rollup@4.14.1_typescript@5.4.5_vue@3.4.21_typescript@5.4.5_/node_modules/@edco/apollo/dist/runtime/plugins/metrics.js";
import plugin_Jr1CwJz1D1 from "/opt/node_app/app/src/modules/gtm/runtime/plugin.ts";
import chunk_reload_client_ZSCOBqAPRL from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.0_axios@1.6.8_encodi_2vi2rgxsanybzwpnbir2uncmk4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import logging_82983bmFHh from "/opt/node_app/app/src/plugins/logging.ts";
export default [
  revive_payload_client_2q83OqER9W,
  unhead_BY7dyDEUEY,
  router_f5Z41H3GNn,
  sentry_6a0ENEp60i,
  logger_SGih1q6VIs,
  payload_client_LS8Qh7kJH4,
  check_outdated_build_client_fN4FW98lXd,
  plugin_vue3_MSCPopQ6GL,
  components_plugin_KR1HBZs4kY,
  prefetch_client_KMYLyoyQBP,
  hydrated_ESeDyKLBMf,
  tooltip_Iij0orJlV0,
  gtag_0bAPy2C5WT,
  theme_rCE95HiHxn,
  middleware_qgtgR0YfcG,
  metrics_vl2jnLjih9,
  plugin_Jr1CwJz1D1,
  chunk_reload_client_ZSCOBqAPRL,
  logging_82983bmFHh
]