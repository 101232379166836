<template>
  <div>
    <NuxtLayout>
      <nuxt-loading-indicator
        :throttle="throttle"
        :duration="duration"
        :height="2"
        color="var(--color-primary-500)" />
      <NuxtPage />
    </NuxtLayout>
    <umbraco-tools />
  </div>
</template>

<script lang="ts" setup>
import { throttle, duration } from "~/utils/loadingIndicator";
import { useHead, useRequestURL } from "#imports";
import type { Script } from "@unhead/vue";

const url = useRequestURL();

const imageUrl = `${url.origin}/images/placeholder_red.png`;

// Check if the hostname includes 'staging'
const isInternal = url.host.includes( "edcowebsites" );
const isLocalhost = url.host.includes( "localhost" ) || url.host.includes( "127.0.0.1" );
const culture = useCurrentCulture();
const isUk = computed( () => culture.value?.toLowerCase() === "en-gb" );

const cultureSpecificScripts = computed( () => {
  const result : Script[] = [];

  if ( !isLocalhost ) {
    if ( isUk.value ) {
      result.push( {
        key: "__ketch",
        innerHTML: "!function(){window.semaphore=window.semaphore||[],window.ketch=function(){window.semaphore.push(arguments)};var e=new URLSearchParams(document.location.search),o=e.has(\"property\")?e.get(\"property\"):\"gl_asmt_marketing\",n=document.createElement(\"script\");n.type=\"text/javascript\",n.src=\"https://global.ketchcdn.com/web/v3/config/renaissance_learning/\".concat(o,\"/boot.js\"),n.defer=n.async=!0,document.getElementsByTagName(\"head\")[0].appendChild(n)}();",
      } );
    } else {
      result.push( {
        key: "__ketch",
        innerHTML: "!function(){window.semaphore=window.semaphore||[],window.ketch=function(){window.semaphore.push(arguments)};var e=new URLSearchParams(document.location.search),o=e.has(\"property\")?e.get(\"property\"):\"gl_marketing\",n=document.createElement(\"script\");n.type=\"text/javascript\",n.src=\"https://global.ketchcdn.com/web/v3/config/renaissance_learning/\".concat(o,\"/boot.js\"),n.defer=n.async=!0,document.getElementsByTagName(\"head\")[0].appendChild(n)}();",
      } );
    }
  }

  return result;
} );

useHead( {
  meta: [
    // Conditionally add the robots meta tag if it's internal
    ...( isInternal ? [{ name: "robots", content: "noindex" }] : [] ),
    {
      key: "og:image",
      property: "og:image",
      content: imageUrl,
    },
    {
      key: "twitter:image",
      name: "twitter:image",
      content: imageUrl,
    },
  ],
  script: [...cultureSpecificScripts.value],
} );
</script>

<style>

</style>
