import { default as TemplatePagedrsJcSPmE6Meta } from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.658_encoding@0.1.13_ioredis@5.3.2_rollup@4.14.1_typescript@5.4.5_vue@3.4.21_typescript@5.4.5_/node_modules/@edco/apollo/dist/runtime/pages/TemplatePage.vue?macro=true";
import { default as KitchenSinkKwSQeqCf2eMeta } from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.658_encoding@0.1.13_ioredis@5.3.2_rollup@4.14.1_typescript@5.4.5_vue@3.4.21_typescript@5.4.5_/node_modules/@edco/apollo/dist/runtime/pages/KitchenSink.vue?macro=true";
import { default as BackofficeComponentPreviewaiVZBbW0B7Meta } from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.658_encoding@0.1.13_ioredis@5.3.2_rollup@4.14.1_typescript@5.4.5_vue@3.4.21_typescript@5.4.5_/node_modules/@edco/apollo/dist/runtime/pages/BackofficeComponentPreview.vue?macro=true";
import { default as ReadyProbeFmPc8C9emiMeta } from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.658_encoding@0.1.13_ioredis@5.3.2_rollup@4.14.1_typescript@5.4.5_vue@3.4.21_typescript@5.4.5_/node_modules/@edco/apollo/dist/runtime/pages/ReadyProbe.vue?macro=true";
export default [
  {
    name: TemplatePagedrsJcSPmE6Meta?.name ?? "template-page",
    path: TemplatePagedrsJcSPmE6Meta?.path ?? "/:slug(.*)*",
    meta: { ...(TemplatePagedrsJcSPmE6Meta || {}), ...{"middleware":["headless-middleware"]} },
    alias: TemplatePagedrsJcSPmE6Meta?.alias || [],
    redirect: TemplatePagedrsJcSPmE6Meta?.redirect,
    component: () => import("/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.658_encoding@0.1.13_ioredis@5.3.2_rollup@4.14.1_typescript@5.4.5_vue@3.4.21_typescript@5.4.5_/node_modules/@edco/apollo/dist/runtime/pages/TemplatePage.vue").then(m => m.default || m)
  },
  {
    name: KitchenSinkKwSQeqCf2eMeta?.name ?? "kitchen-sink",
    path: KitchenSinkKwSQeqCf2eMeta?.path ?? "/_kitchen-sink",
    meta: { ...(KitchenSinkKwSQeqCf2eMeta || {}), ...{"layout":false,"pageTransition":false,"layoutTransition":false} },
    alias: KitchenSinkKwSQeqCf2eMeta?.alias || [],
    redirect: KitchenSinkKwSQeqCf2eMeta?.redirect,
    component: () => import("/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.658_encoding@0.1.13_ioredis@5.3.2_rollup@4.14.1_typescript@5.4.5_vue@3.4.21_typescript@5.4.5_/node_modules/@edco/apollo/dist/runtime/pages/KitchenSink.vue").then(m => m.default || m)
  },
  {
    name: BackofficeComponentPreviewaiVZBbW0B7Meta?.name ?? "umb-backoffice-component-preview",
    path: BackofficeComponentPreviewaiVZBbW0B7Meta?.path ?? "/umb-backoffice-component-preview",
    meta: { ...(BackofficeComponentPreviewaiVZBbW0B7Meta || {}), ...{"layout":false,"pageTransition":false,"layoutTransition":false} },
    alias: BackofficeComponentPreviewaiVZBbW0B7Meta?.alias || [],
    redirect: BackofficeComponentPreviewaiVZBbW0B7Meta?.redirect,
    component: () => import("/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.658_encoding@0.1.13_ioredis@5.3.2_rollup@4.14.1_typescript@5.4.5_vue@3.4.21_typescript@5.4.5_/node_modules/@edco/apollo/dist/runtime/pages/BackofficeComponentPreview.vue").then(m => m.default || m)
  },
  {
    name: ReadyProbeFmPc8C9emiMeta?.name ?? "nuxt-ready-probe",
    path: ReadyProbeFmPc8C9emiMeta?.path ?? "/_probes/nuxt/ready",
    meta: { ...(ReadyProbeFmPc8C9emiMeta || {}), ...{"layout":false,"pageTransition":false,"layoutTransition":false} },
    alias: ReadyProbeFmPc8C9emiMeta?.alias || [],
    redirect: ReadyProbeFmPc8C9emiMeta?.redirect,
    component: () => import("/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.658_encoding@0.1.13_ioredis@5.3.2_rollup@4.14.1_typescript@5.4.5_vue@3.4.21_typescript@5.4.5_/node_modules/@edco/apollo/dist/runtime/pages/ReadyProbe.vue").then(m => m.default || m)
  }
]